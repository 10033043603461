<template>
  <div class="text-center">
    <h1>
      404: Not Found!
    </h1>
    <div class="my-5 title">De pagina die je probeert te vinden is niet gevonden</div>
    <v-btn color="primary" @click="home">Terug naar de homepage</v-btn>
  </div>
</template>

<script>

export default {
  name: 'not-allowed',
  data() {
    return {};
  },
  methods: {
    home() {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
